import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const SecurityIncident = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="SecurityIncident"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">In a technology-driven world, every organization regardless of its size is trying to keep up with the frequent and evolving cybersecurity threats.</p>
                        <p className="mt-3">With the breach in cybercrime becoming easier, detection of threats taking too long, response times affecting businesses, and lack of cyber skills, it has become imperative for organizations to use advanced techniques to proactively detect and respond to these threats to keep their environment safe.</p>
                        <p className="mt-2">TEQT addresses these challenges for the customers through the integrated Security Incident Management Platform - “SIMP” to deliver Next-Gen SOC capabilities.</p>
                        <p className="mt-2">SIMP Integrates security solutions to achieve a cohesive security environment, leverage current investment and optimises data sources while providing real-time preventive and detective response capabilities to increase productivity & effectiveness of IT Security.</p>
                        <p className="mt-2">TEQT Next-Gen Security Operations helps in SIMPlifying IT Security through our integrated platform as below:</p>
                        <h6>Features of SIMP include the following:</h6>

                        <ul className="circle-list">
                            <li className="mt-2"> <span className="text-black">Alert handling powered by AI and machine learning:</span>Up to 32% reduction in L2/L3 effort through auto-remediation & smart automation.</li>
                            <li className="mt-2"><span className="text-black">Orchestration library-based remediation:</span>Verticalized use cases - 208 scenarios, 40 customizable. Automation and Orchestrations for reduced response time</li>
                            <li className="mt-2"><span className="text-black"> Scalable service:</span>combined with low operations and maintenance cost</li>
                            <li className="mt-2"> <span className="text-black">Cross-functional architecture:</span>everaging existing investments to integrate with diverse data sources.</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query SecurityIncidentPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

SecurityIncident.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default SecurityIncident;
